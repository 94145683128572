<template>
  <v-container>
    <vue-snotify></vue-snotify>
    <v-card>
      <v-card-title class="cyan darken-1">
        <span class="white--text">Crear contratos</span>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid" lazy-validation>
          <br />
          <v-row>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" md="4">
              <v-select
                v-model="id_customer"
                :items="customers"
                item-text="legal_name"
                item-value="id"
                prepend-icon="mdi-store"
                label="Seleccionar empresa *"
                disabled="false"
                persistent-hint
              ></v-select>
              <v-select
                v-model="id_plan"
                :items="plans"
                item-text="name"
                item-value="id"
                prepend-icon="mdi-octagram"
                label="Seleccionar plan *"
                persistent-hint
                :disabled="params"
              ></v-select>
              <v-select
                v-model="id_billing_periods"
                :items="billingPeriods"
                item-text="billing_period"
                item-value="id"
                prepend-icon="mdi-view-list"
                label="Seleccionar día de facturación *"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12" md="4"></v-col>
            <v-col cols="12" lg="6"> </v-col>

            <v-col cols="12" lg="6"> </v-col>

            <v-col cols="12" lg="6"> </v-col>
          </v-row>
          <br /><br />
          <v-divider></v-divider> <br />
          <v-row>
            <v-col cols="12" lg="8"></v-col>
            <v-col cols="12" lg="4">
              <v-btn
                color="primary"
                elevation="3"
                class="mr-4"
                outlined
                @click="cancel"
              >
                <v-icon>mdi-cancel</v-icon>Cancelar
              </v-btn>
              <v-btn
                color="primary"
                elevation="3"
                class="mr-4"
                outlined
                @click="create"
              >
                <v-icon>mdi-credit-card-outline</v-icon>Crear
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>
<script>
import axios from "axios";
import VueSnotify from "vue-snotify";
import SnotifyMixin from "@/mixins/DisplayNotificationMixin.vue";
import auth from "@/logic/auth";
export default {
  mixins: [SnotifyMixin],
  VueSnotify,

  data: () => ({
    customers: [],
    plans: [],
    billingPeriods: [],
    customer: "",
    id_customer: "",
    id_plan: "",
    id_billing_periods: "",
    params: false,
  }),
  mounted() {
    console.log("datos de usuario autnticado");
    console.log(auth.getUserName());
    this.getCustomer();
    this.getCustomers();
    this.getPlans();
    this.getBillingPeriods();
    this.id_plan = this.$route.params.id_plan;
    this.params = this.$route.params.id_plan == null ? false : true;
  },

  methods: {
    cancel() {
      this.$router.push({
        name: "my-contracts",
        params: { id: this.id_customer },
      });
    },
    getCustomer() {
      if (auth.getUserRut() != null) {
        let request = {
          rut: auth.getUserRut(),
        };
        axios
          .post("/customer-by-rut", request)
          .then((response) => {
            this.id_customer = response.data.id;
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification("error", "Error", err);
            setTimeout(() => this.$router.push({ name: "contracts" }), 4000);
          });
      }
    },

    create() {
      console.log("creando contrato");
      if (!auth.getUserName()) {
        this.$router.push({ name: "login" });
      }
      let request = {
        id_customer: this.id_customer,
        id_plan: this.id_plan,
        id_billing_periods: this.id_billing_periods,
      };
      console.log(request);
      if (this.id_customer) {
        axios
          .post("/save-contract", request)
          .then((response) => {
            this.displayNotification("success", "Éxito", response.data);
            console.log(response.data);
            setTimeout(
              () =>
                this.$router.push({
                  name: "my-contracts",
                  params: { id: this.id_customer },
                }),
              4000
            );
          })
          .catch((err) => {
            console.log(err, "error");
            this.displayNotification("error", "Error", err);
            setTimeout(() => this.$router.push({ name: "contracts" }), 4000);
          });
      } else {
        this.displayNotification(
          "error",
          "Error",
          "Debe seleccionar un servicio"
        );
      }
    },

    getCustomers() {
      axios
        .get("/customers")
        .then((response) => {
          console.log(response.data);
          this.customers = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener clientes"
          );
        });
    },
    getPlans() {
      axios
        .get("/plans")
        .then((response) => {
          console.log(response.data);
          this.plans = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification("error", "Error", "Error al obtener planes");
        });
    },
    getBillingPeriods() {
      axios
        .get("/billing-periods")
        .then((response) => {
          console.log(response.data);
          this.billingPeriods = response.data;
        })
        .catch((err) => {
          console.log(err, "error");
          this.displayNotification(
            "error",
            "Error",
            "Error al obtener dias de facturacion"
          );
        });
    },
  },
};
</script>